<template>
  <v-card v-if="show" class="chat-container">
    <v-card-title style="padding-bottom: 0">
      <div class="chat-header">
        <span class="truncate-title">{{ title }}</span>
        <v-btn icon @click="closeBox">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-card-title>

    <v-card-text class="chat-body">
      <div class="chat-history">
        <div v-for="(msg, index) in messages" :key="index"
             :class="['d-flex flex-row align-center my-2', msg.sender.userId === userId ? 'justify-end': null]">
          <span v-if="msg.sender.userId === userId" class="white--text mr-3 message">{{ msg.message }}</span>
          <v-avatar :color="secondaryColor" size="22">
            <span class="white--text" style="font-size: smaller">{{ getInitials(msg.sender.nickname) }}</span>
          </v-avatar>
          <span v-if="msg.sender.userId !== userId" class="white--text ml-3 message">{{ msg.message }}</span>
        </div>
      </div>
      
      <v-textarea
        v-model="msg"
        outlined
        label="Write a message..."
        rows="1"
        hide-details
        class="custom-textarea"
        @keydown.enter="handleKeyPress"
      ></v-textarea>

      <v-btn block :color="primaryColor" class="white--text" @click="sendMessage">
        Send
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import colors from "../../constants/colors";
import chatService from "@/services/chatService";

export default {
  name: "ChatDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    messageThread: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      title: "",
      msg: "",
      messages: [],
      userId: null,
      primaryColor: colors.BRAND_PRIMARY_COLOR_3,
      secondaryColor: colors.BRAND_PRIMARY_COLOR_1,
      lightGrayColor: colors.LIGHT_GRAY,
    };
  },
  methods: {
    async init() {
      this.msg = "";
      if (this.messageThread != null) {
        this.title = this.messageThread.companyNames;
        this.userId = this.messageThread.userId;
        this.messages = this.messageThread.messages;
      }
    },
    getInitials(name) {
      if (name === "")
        return "";
      
      return name
        .match(/\b(\w)/g)
        .join("")
        .substring(0, 2)
        .toUpperCase();
    },
    closeBox() {
      this.$emit("close");
    },
    async handleKeyPress(event) {
      // Add newline on Shift+Enter otherwise send message
      if (!event.shiftKey) {
        event.preventDefault();
        await this.sendMessage();
      }
    },
    async sendMessage() {
      if (!this.msg.trim()) {
        this.$root.$snackbar.message("Please write a message before sending.");
        return;
      }

      try {
        const toParty = {
          id: this.messageThread.connectedCompanyId || 0, 
          name: this.messageThread.companyNames || "",
          initials: this.messageThread.companyNames.charAt(0).toUpperCase(),
          userId: this.messageThread.userId || "",
          connectedCompanyCode: this.messageThread.connectedCompanyCode || "",
          connectedPartyId: this.messageThread.connectedPartyId || 0,
        };

        await chatService.sendMessage(toParty, this.msg);

        console.log("Message sent:", this.msg);
        this.msg = ""; 
      } catch (error) {
        console.error("Error sending message:", error);
        this.$root.$snackbar.error("Failed to send the message. Please try again.");
        this.messages.shift();
      }
    },
  },
  watch: {
    messageThread() {
      this.init();
    },
  },
}
</script>

<style scoped>
.chat-container {
  position: fixed;
  bottom: 20px;
  right: 370px;
  width: 300px;
  z-index: 2000;
  max-height: 380px;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 0.8em;
}

.chat-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.chat-history {
  overflow-y: auto;
  max-height: 200px;
  display: flex;
  flex-direction: column-reverse;
}

.truncate-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message {
  border-radius: 5px;
  background-color: #009BDB;
  padding: 0 4px;
  font-size: small;
  line-height: 1.1rem;
  white-space: pre-line;
}

.custom-textarea /deep/ textarea {
  font-size: small;
  line-height: 1.1rem;
}
</style>